.footerMainContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    background: black;
    width: 100%;
}

.footerSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: black;
    width: 80%;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}

.logoRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
}

.copyrightCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 20px;
    color: white;
}

.dummy{

}